import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { Footer } from "../../shared/footer";
import { Header } from "../../shared/header";

export const Privacy = ({}) => {
  useAnalytics();

  return (
    <div>
      <Header staticColor={true}></Header>
      <Container
        itemWidth="100%"
        columnWidths={["100%"]}
        style={{ width: "100%" }}
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: "3rem",
            fontWeight: 900,
            marginTop: "25%",
          }}
        >
          SMARTMIETER DATENSCHUTZ
        </h1>
        <p style={{ color: "#AAA6A6", width: "50%", marginLeft: "25%" }}>
          Vielen Dank für Ihr Interesse an SMARTMIETER. Der Schutz Ihrer
          persönlichen Daten ist uns bei SMARTMIETER sehr wichtig. Wir
          verpflichten uns, Ihre Daten sicher und verantwortungsbewusst zu
          behandeln. Diese Datenschutzerklärung erklärt Ihnen klar und
          verständlich, welche Daten wir erheben, wie wir diese verwenden und
          welche Rechte Sie im Hinblick auf Ihre Daten haben. Unser Ziel ist es,
          Ihnen größtmögliche Transparenz zu bieten und Ihnen die Kontrolle über
          Ihre Daten zu ermöglichen.
        </p>
        <Notice></Notice>
      </Container>
      <Footer></Footer>
    </div>
  );
};

const SectionTitle = ({ children }) => (
  <h3 style={{ fontSize: "1.5em", margin: "1em 0", fontWeight: "bold" }}>
    {children}
  </h3>
);

const Notice = () => (
  <div
    style={{
      width: "50%",
      marginLeft: "25%",
      marginTop: "20%",
      marginBottom: "10%",
    }}
  >
    <h2>Datenschutzerklärung für SMARTMIETER</h2>

    <p>
      Der Schutz Ihrer persönlichen Daten ist uns bei SMARTMIETER besonders
      wichtig. Wir legen großen Wert auf Transparenz und möchten Ihnen
      verständlich machen, welche Daten wir erheben, wie wir diese verwenden und
      welche Rechte Sie in Bezug auf Ihre Daten haben. Diese
      Datenschutzerklärung informiert Sie über die Art, den Umfang und den Zweck
      der Verarbeitung personenbezogener Daten auf unserer Website und unseren
      Diensten.
    </p>

    <SectionTitle>1. Verantwortlicher für die Datenverarbeitung</SectionTitle>
    <p>
      Verantwortlich für die Verarbeitung Ihrer personenbezogenen Daten im Sinne
      der Datenschutz-Grundverordnung (DSGVO) ist:
      <br />
      SMARTMIETER UG <br />
      Reichenhallerstraße, 25 81547 München
      <br />
      hello@smartmieter.com <br />
    </p>

    <SectionTitle>
      2. Erhebung und Speicherung personenbezogener Daten
    </SectionTitle>
    <p>
      Wir erheben und verarbeiten Ihre personenbezogenen Daten nur, wenn dies
      gesetzlich erlaubt ist oder Sie Ihre Einwilligung dazu gegeben haben.
      Folgende Daten können dabei erhoben werden:
      <ul>
        <li>Basisdaten bei der Registrierung (Name, E-Mail-Adresse, etc.)</li>
        <li>Zahlungsdaten bei kostenpflichtigen Leistungen</li>
        <li>Kommunikationsdaten, falls Sie mit uns in Kontakt treten</li>
        <li>
          Automatisch erfasste Daten bei der Nutzung unserer Website (z.B.
          IP-Adresse, Browsertyp, Zugriffszeiten)
        </li>
      </ul>
    </p>

    <SectionTitle>3. Verarbeitungszwecke und Rechtsgrundlagen</SectionTitle>
    <p>
      Wir verarbeiten Ihre Daten zu folgenden Zwecken und basierend auf den
      jeweiligen Rechtsgrundlagen:
      <ul>
        <li>
          Zur Erfüllung vertraglicher Pflichten gemäß Art. 6 Abs. 1 lit. b
          DSGVO, insbesondere für die Bereitstellung unserer
          Beratungsdienstleistungen.
        </li>
        <li>
          Auf Basis Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO, z.B.
          für den Versand von Newslettern.
        </li>
        <li>
          Zur Wahrung berechtigter Interessen gemäß Art. 6 Abs. 1 lit. f DSGVO,
          z.B. zur Verbesserung unserer Dienste oder zur Betrugsprävention.
        </li>
        <li>
          Zur Erfüllung gesetzlicher Anforderungen gemäß Art. 6 Abs. 1 lit. c
          DSGVO.
        </li>
      </ul>
    </p>

    <SectionTitle>4. Weitergabe von Daten an Dritte</SectionTitle>
    <p>
      Eine Weitergabe Ihrer personenbezogenen Daten an Dritte erfolgt nur, wenn
      dies zur Vertragserfüllung erforderlich ist, Sie ausdrücklich eingewilligt
      haben oder wir gesetzlich dazu verpflichtet sind. Zu den Empfängern können
      gehören:
      <ul>
        <li>Partner-Anwälte für die Erbringung von Beratungsleistungen</li>
        <li>Zahlungsdienstleister zur Abwicklung von Transaktionen</li>
        <li>
          IT-Dienstleister für den Betrieb und die Wartung unserer Website
        </li>
      </ul>
    </p>

    <SectionTitle>5. Speicherung und Löschung von Daten</SectionTitle>
    <p>
      Wir speichern Ihre personenbezogenen Daten nur so lange, wie es für die
      Erfüllung der genannten Zwecke erforderlich ist oder gesetzliche
      Aufbewahrungsfristen dies verlangen. Nach Ablauf der Speicherfristen
      werden Ihre Daten entsprechend den gesetzlichen Vorschriften gelöscht.
    </p>

    <SectionTitle>6. Ihre Rechte als betroffene Person</SectionTitle>
    <p>
      Sie haben folgende Rechte im Hinblick auf Ihre personenbezogenen Daten:
      <ul>
        <li>Recht auf Auskunft über die gespeicherten Daten (Art. 15 DSGVO)</li>
        <li>Recht auf Berichtigung unrichtiger Daten (Art. 16 DSGVO)</li>
        <li>
          Recht auf Löschung („Recht auf Vergessenwerden“) (Art. 17 DSGVO)
        </li>
        <li>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</li>
        <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
        <li>Widerspruchsrecht gegen die Verarbeitung (Art. 21 DSGVO)</li>
        <li>
          Recht, eine Einwilligung jederzeit mit Wirkung für die Zukunft zu
          widerrufen (Art. 7 Abs. 3 DSGVO)
        </li>
      </ul>
      Zur Ausübung Ihrer Rechte wenden Sie sich bitte an die oben angegebene
      Kontaktadresse.
    </p>

    <SectionTitle>7. Datensicherheit</SectionTitle>
    <p>
      Wir setzen technische und organisatorische Maßnahmen ein, um Ihre Daten
      gegen unbefugten Zugriff, Verlust oder Manipulation zu schützen. Unsere
      Sicherheitsmaßnahmen werden regelmäßig überprüft und an aktuelle Standards
      angepasst.
    </p>

    <SectionTitle>8. Einsatz von Cookies und Analyse-Tools</SectionTitle>
    <p>
      Unsere Website verwendet Cookies und ähnliche Technologien, um die Nutzung
      der Website zu analysieren und unser Angebot zu verbessern. Sie können die
      Verwendung von Cookies in den Einstellungen Ihres Browsers anpassen. Für
      die Webanalyse setzen wir [Tool-Name] ein, welches Daten zur Nutzung
      unserer Website erhebt und speichert. Weitere Informationen finden Sie in
      unserer <a href="/cookie-richtlinie">Cookie-Richtlinie</a>.
    </p>

    <SectionTitle>9. Änderungen dieser Datenschutzerklärung</SectionTitle>
    <p>
      Wir behalten uns das Recht vor, diese Datenschutzerklärung anzupassen, um
      sie an geänderte rechtliche Anforderungen oder Änderungen unserer
      Dienstleistungen anzupassen. Die jeweils aktuelle Fassung ist auf unserer
      Website abrufbar.
    </p>

    <p>Stand: 10. Dezember 2024</p>
  </div>
);
