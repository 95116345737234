import { Footer } from "../shared/footer";
import { Header } from "../shared/header";
import { HeroSection } from "../Home/components/hero";
import React, { useState } from "react";
import "./vertrag.css";
import { Explainer } from "../shared/explainer";
import axios from "axios";
import { useAuth } from "../Auth/provider";
import { useAnalytics } from "../analytics";
import { getStageConfig } from "../config/config";

export const Vertrag = () => {
  const config = getStageConfig();
  const [pdfFile, setPdfFile] = useState(null); // Stores the file object
  const [pdfUrl, setPdfUrl] = useState(null); // Stores the URL for rendering
  const [fileName, setFileName] = useState(""); // Stores the name of the selected file
  const [submitted, setSubmitted] = useState(false); // Track submission state
  const [loading, setLoading] = useState(false); // Track loading state
  const { user } = useAuth();

  useAnalytics();

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setPdfFile(file); // Save the file object
      setFileName(file.name); // Display the file name
    } else {
      alert("Please upload a valid PDF file.");
      setFileName(""); // Clear file name if it's not a PDF
    }
  };

  const onSubmit = async () => {
    if (pdfFile) {
      setLoading(true); // Start loading when the process begins
      const formData = new FormData();
      formData.append("pdf", pdfFile);

      try {
        const response = await fetch("http://127.0.0.1:5000/upload", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          const result = await axios.post(
            `${config.API_URL}/default/users/poop/contracts`
          );
          console.log(pdfFile);
          if (result.status === 200) {
            const signedUrlPdf = result.data.presignedUrl_pdf;
            const signedUrlTxt = result.data.presignedUrl_txt;
            try {
              await axios.put(signedUrlPdf, pdfFile, {
                headers: {
                  "Content-Type": "application/pdf", // Use the file's MIME type
                },
              });
              await axios.put(signedUrlTxt, data.text, {
                headers: {
                  "Content-Type": "application/pdf", // Use the file's MIME type
                },
              });
              console.log("Files uploaded successfully!");
            } catch (error) {
              console.error("Error uploading file:", error);
              throw error;
            }
          }
          setPdfUrl(URL.createObjectURL(pdfFile)); // Set the PDF URL for rendering
          setSubmitted(true); // Mark as submitted to render the iframe
        } else {
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false); // End loading when process completes or fails
      }
    } else {
      alert("Please upload a PDF file before submitting.");
    }
  };

  return (
    <div className="vertrag-app">
      <Header />
      <HeroSection
        title1="FRAGWÜRDIGER"
        title2="VERTRAG?"
        text1="Lass uns Ihren Vertrag analysieren, und erfahre"
        text2="was für Klausen rechtswiedrig sind."
        url={require("../Home/assets/hero-reasons.png")}
      ></HeroSection>
      <div id="upload-parent">
        <div id="upload-narrow"></div>
        <Explainer
          title1="Wie es funktioniert:"
          instructions={[
            "Sie laden Ihren Vertrag als PDF hoch",
            "Ihr Mietvertrag wird von unserer KI für widerrechtliche Klausen durchsucht",
            "Sie bekommen ein ersten Einblick in die Entdeckte Klausen",
            "Sie können unserem Chat über Ihren Vertrag Fragen stellen.",
          ]}
        />
        <div id="upload-wide">
          {loading ? (
            <div className="loading-container">
              <p>
                Ihr Vertrag wird sicher bearbeitet, bitte warten Sie ein
                Moment...
              </p>
              <div className="loading-spinner"></div> {/* Custom spinner */}
            </div>
          ) : submitted ? (
            <iframe
              src={pdfUrl}
              className="pdf-viewer"
              title="PDF Viewer"
            ></iframe>
          ) : (
            <div className="upload-container">
              <h2>Laden Sie ihren Mietvertrag als PDF hoch</h2>

              <label htmlFor="fileUpload" className="upload-button">
                Wähle PDF
              </label>
              <input
                id="fileUpload"
                type="file"
                onChange={onFileChange}
                style={{ display: "none" }} // Hide the default file input
              />
              {fileName ? (
                <p className="file-name">Ausgewähltes PDF: {fileName}</p>
              ) : (
                <p className="file-name">Keine PDF ausgewählt</p>
              )}

              <button onClick={onSubmit} className="submit-button">
                Analysieren
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
