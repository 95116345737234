import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";

export const FAQ = ({}) => {
  useAnalytics();
  return (
    <Container
      itemsPerRow={1}
      itemWidth="100%"
      style={{
        height: "40rem",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
      }}
    >
      <p
        style={{
          fontWeight: 800,
          fontSize: "2.5rem",
          textAlign: "center",
          marginTop: "10%",
        }}
      >
        FAQS ZU SMARTMIETER
      </p>
      <Container
        itemsPerRow={4}
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "2%",
          justifyContent: "space-between",
          alignContent: "center",
          width: "90%",
        }}
      >
        <FAQCard
          title={"So läuft die Beratung bei Smartmieter ab"}
          subtitle={"Lorem Ipsum"}
          imageSrc={require("../assets/phone-missing-dynamic-clay.png")}
        ></FAQCard>
        <FAQCard
          title={"So erstellen Sie Ihren Smartmieter Account"}
          subtitle={"Lorem Ipsum"}
          imageSrc={require("../assets/cube-dynamic-clay.png")}
        ></FAQCard>
        <FAQCard
          title={"Unsere Leistungen bei der Hilfe rund um Ihre Miete"}
          subtitle={"Lorem Ipsum"}
          imageSrc={require("../assets/copy-dynamic-clay.png")}
        ></FAQCard>
        <FAQCard
          title={"So können Sie mit Smartmieter Geld sparen"}
          subtitle={"Lorem Ipsum"}
          imageSrc={require("../assets/wallet-dynamic-clay.png")}
        ></FAQCard>
      </Container>
    </Container>
  );
};

const FAQCard = ({ title, subtitle, imageSrc }) => {
  const cardStyles = {
    width: "19rem",
    height: "21rem",
    border: "1px solid",
    borderRadius: "1rem",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  };

  const imageStyles = {
    width: "50%",
    marginTop: "5%",
    margin: "auto",
  };

  const textStyles = {
    marginLeft: "5%",
  };

  return (
    <div style={cardStyles}>
      <Container>
        <img style={imageStyles} src={imageSrc}></img>
        <p style={{ ...textStyles, fontSize: "1.2rem", fontWeight: 800 }}>
          {title}
        </p>
        <p style={{ ...textStyles, color: "#868080" }}>{subtitle}</p>
      </Container>
    </div>
  );
};
